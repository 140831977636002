@import url(https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Roboto+Condensed:300,300i,400,400i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app{text-align:center;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-family:'Roboto Condensed';font-size:calc(10px + 2vmin)}hr{width:50%;border:1px solid black;margin:1em 0}.code-field{width:75%}.button-container{margin:0}.add-button{margin:2em 1em !important;padding:0.5em 1em;cursor:pointer}.add-button.inline{display:inline-block}.bot-element{background-color:#f5f5f5;margin:1em;padding:1em;width:75%;text-align:left;border-radius:6px;position:relative}.element-hint{font-size:small;margin-bottom:1.5em;color:gray}.option-remove-button{font-size:0.8em;color:#646464;text-align:center;border-radius:4px;cursor:pointer;background:transparent;border-color:gray}.element-remove-button{font-size:0.8em;position:absolute;top:5px;right:8px;cursor:pointer}.element-id{font-weight:700;padding:0.5em}.element-field{width:100%;margin:1em 0 !important}.option{position:relative;padding:.5em 0;display:flex}.option-cell{width:calc(100%);padding-right:.5em}.option-field{width:100%}.trigger-select{width:100%}.chat-bot-container{position:fixed;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);text-align:left;z-index:10}.chat-bot-container .chat-bot-close-button{position:absolute;top:5px;right:8px;cursor:pointer;color:white;z-index:1000}

